<!--走进园区  服务内容-->
<template>
  <div class="servewrap">
    <headbox></headbox>
    <div class="indexbox">
      <!-- 滑块组件 -->
      <banner positionSid="2"></banner>

<!--      <div class="swiper-container">-->
<!--        <div class="swiperbgimg"></div>-->
<!--        <div class="swiper-wrapper">-->
<!--          &lt;!&ndash; <div class="swiper-slide">-->
<!--                      <img class="swiperimg" src="@/assets/images/yqlnswiper1.jpg">-->
<!--                    </div> &ndash;&gt;-->
<!--          <div-->
<!--            class="swiper-slide"-->
<!--            v-for="(item, index) in swiper.imgs"-->
<!--            :key="index"-->
<!--          >-->
<!--            <img class="swiperimg" :src="item.imgurl" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!--园区介绍 废弃-->
      <div class="yqjswrap" v-if="1==0">
        <div class="yqjscontent">
          <div class="yqjstitle">{{yqjs.title}}</div>
          <div class="yqjstitle2">INTRODYCTION TO THE COMPANY</div>
          <div class="yqjs_item">
            <img
              class="yqjsimg wow animate__animated animate__fadeInLeft"
              :src="yqjs.img"
            />
            <div class="yqjstext wow animate__animated animate__fadeInRight">
              <div v-html="yqjs.text"></div>
            </div>
          </div>
        </div>
      </div>
      <!--公司理念 废弃-->
      <div class="yqlnwrap" v-if="1==0">
        <div class="yqlncontent">
          <!-- <div class="yqlntitle">园区理念</div> -->
          <div class="yqlntitle">{{yqln.title}}</div>
          <div class="yqlntitle2">COMPANY CONCEPT</div>
          <div class="yqlntext wow animate__animated animate__bounceIn" v-html="yqln.text">
<!--            国外SNS社交平台Facebook、YouTube、-->
<!--            Twitter、Linkedln、Tumb《等全方位运营，专业内容出身，并基于战略伙伴·中国网-->
<!--            "等多个官媒平台和自有各类国内自媒体号，深得社交平台精髓！-->
<!--            深入融合和依吒浙商组织，数十年聚焦"浙商-->
<!--            "积累经验，已成为"浙商"地标性的服务机构。提出的“大道至简，实干为要。共赢共享，通舟共济”的发展观，充分发挥政策红利和时域优势，致力于调动市场中的经济发展势能，汇集杭州，促进杭州经济-->
<!--            发展突破地域限制，实现建设“主动引导型”跨境产业的目标，打造成为中国第一跨境产业集群。-->
          </div>
          <div class="yqlnbox wow animate__animated animate__fadeInUp">
            <!-- <div class="yqln_item">
                    <img class="yqln_item_img" src="../../assets/images/yqln1.png" />
                    <div class="yqln_item_cont">
                      <div class="yqln_item_title">零门槛</div>
                      <div class="yqln_item_text">面向优质电商企业、直播网红达人MCN机构，无地域限制，无经营市场主体类型限制</div>
                    </div>
                  </div>
                  <div class="yqln_item">
                    <img class="yqln_item_img" src="../../assets/images/yqln2.png" />
                    <div class="yqln_item_cont">
                      <div class="yqln_item_title">零跑动</div>
                      <div class="yqln_item_text">在线领取执照，在线受理投诉，银行开户、刻章备案等全程电子化操作</div>
                    </div>
                  </div>
                  <div class="yqln_item">
                    <img class="yqln_item_img" src="../../assets/images/yqln3.png" />
                    <div class="yqln_item_cont">
                      <div class="yqln_item_title">零费用</div>
                      <div class="yqln_item_text">免费办理营业执照，免费提供合规的注册地址</div>
                    </div>
                  </div>
                  <div class="yqln_item">
                    <img class="yqln_item_img" src="../../assets/images/yqln4.png" />
                    <div class="yqln_item_cont">
                      <div class="yqln_item_title">全方位</div>
                      <div class="yqln_item_text">为商家提供专业优质的财税、审计、知识产权、运营培训等一站式服务，助力园区电商快速成长</div>
                    </div>
                  </div> -->
            <div
              class="yqln_item"
              v-for="(item, index) in yqln.list"
              :key="index"
            >
              <img class="yqln_item_img" :src="item.notion_Logo" />
              <div class="yqln_item_cont">
                <div class="yqln_item_title">{{ item.notion_Name }}</div>
                <div class="yqln_item_text" v-html="item.notion_Content"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>



<!--    全部放到单页管理里面-->
    <!--产品核心  单页面组件-->
     <Singlepage></Singlepage>


    <!--运营模式 --服务内容  -->
    <div class="yqjswrap"  v-if="1==0">
      <div class="yqjscontent">
        <div class="yqjstitle">{{yyms.content.pattern_Name}}</div>
<!--        <div class="yqjstitle2">INTRODYCTION TO THE PARK</div>-->
        <div class="yqjs_item">
          <img
                  class="yqjsimg wow animate__animated animate__fadeInLeft"
                  :src="yyms.content.pattern_Logo"
          />
          <div class="yqjstext wow animate__animated animate__fadeInRight">
            <div v-html="yyms.content.pattern_Content"></div>
          </div>
        </div>
      </div>
    </div>


<!--    &lt;!&ndash; 关于服务  &ndash;&gt;-->
<!--    <div class="yqjswrap" >-->
<!--      <div class="yqjscontent">-->
<!--        <div class="yqjstitle">{{gyfw.title}}</div>-->
<!--        &lt;!&ndash;        <div class="yqjstitle2">INTRODYCTION TO THE PARK</div>&ndash;&gt;-->
<!--        <div class="yqjs_item">-->
<!--          <img-->
<!--                  class="yqjsimg wow animate__animated animate__fadeInLeft"-->
<!--                  :src="gyfw.logo"-->
<!--          />-->
<!--          <div class="yqjstext wow animate__animated animate__fadeInRight">-->
<!--            <div v-html="gyfw.content"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <!--配套服务-->
    <div class="ptfwwrap" v-if="1==0">
      <div class="ptfwcontent">
        <div class="ptfwtitle">配套服务</div>
        <div class="ptfwtitle2">SUPPORTING SERVICES</div>
      </div>
      <div class="fwxrow wow animate__animated animate__fadeInUp">
        <!-- <div class="fwx_item">
          <img src="../assets/images/zxyhkh.png"/>
          <div class="fwxtext">在线银行开户</div>
        </div>
        <div class="fwx_item">
          <img src="../assets/images/fpxgyw.png"/>
          <div class="fwxtext">发票相关业务</div>
        </div>
        <div class="fwx_item">
          <img src="../assets/images/dzqzfw.png"/>
          <div class="fwxtext">电子签章服务</div>
        </div>
        <div class="fwx_item">
          <img src="../assets/images/zscqfw.png"/>
          <div class="fwxtext">知识产权服务</div>
        </div> -->
        <div class="fwx_item" v-for='(item,index) in service.list' :key="index">
          <img class="serviceimg_item" :src="item.service_Logo"/>
          <div class="fwxtext">{{item.type_Name}}</div>
        </div>
      </div>
    </div>

    <!-- 侧边导航组件 -->
    <sidebar ref="mysiderbar"></sidebar>
<!--    底部组件-->
<!--    <foot></foot>-->
<!--    ICP组件-->
    <bottom></bottom>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
//import headbox from "@/components/head";
import headbox from "@/components/headfloat";
import foot from "@/components/foot";
import bottom from '@/components/bottom';
import { yqjsapi, yqlnapi, yymsapi, swiperapi,singleapi } from "@/api/zjyqapi.js";
import { indexserviceapi} from '@/api/index.js';
import sidebar from "@/components/sidebar";
import { staticurl } from "@/utils/baseurl";
import { saveSession } from "@/utils/windowStorage";
import wow from "wowjs";
import Singlepage from "@/components/Singlepage.vue";
import Banner from "@/components/banner.vue";

export default {
  components: {
    Banner,
    Singlepage,
    headbox,
    foot,
    bottom,
    sidebar,
  },
  data() {
    return {

      mySwiper: {},
      swiper: {
        imgs: [],
      },
      service:{
        list: []
      },
      single:{
        list: []
      },
      tabbar_active: 0,
      yqjs: {
        title:"公司介绍",
        text: "",
        img: "",
      },
      yqln: { //   公司理念
        title:"公司理念",
        text: "            国外SNS社交平台Facebook、YouTube、\n" +
                "            Twitter、Linkedln、Tumb《等全方位运营，专业内容出身，并基于战略伙伴·中国网\n" +
                "            \"等多个官媒平台和自有各类国内自媒体号，深得社交平台精髓！\n" +
                "            深入融合和依吒浙商组织，数十年聚焦\"浙商\n" +
                "            \"积累经验，已成为\"浙商\"地标性的服务机构。提出的“大道至简，实干为要。共赢共享，通舟共济”的发展观，充分发挥政策红利和时域优势，致力于调动市场中的经济发展势能，汇集杭州，促进杭州经济\n" +
                "            发展突破地域限制，实现建设“主动引导型”跨境产业的目标，打造成为中国第一跨境产业集群。",
        list: [],
      },
      gyfw:{
        title:'', //  关于服务
        logo:'',
        content:'',
      },
      cchx:{
        title:'', // 产品核心
        logo:'',
        content:'',
      },
      yyms: {
        content: {
          pattern_Logo:'',
          pattern_Name: "服务内容",
          pattern_Content: `<div class="glmsrow">管理架构</div>
                                  <div class="glmsrow">盼云跨境（中国杭州）电子商务产业园</div>
                                  <div class="glmsrow">运营管理单位：浙江盼云跨境运营管理有限公司</div>
                                  <div class="glmsrow">管理架构</div>
                                  <div class="glmsrow">政府主导+名企引领+创业者为主体</div>`,
        },
      },
    };
  },

  methods: {
    hideChat(e) {
      this.$refs.mysiderbar.hideChat();
    },
    yqtabbar(index) {
      this.tabbar_active = index;
    },
  },
  beforeMount() {
    let projectid = this.$store.state.project.projectid;
    if (this.$store.state.project.servedata) {
      yqjsapi({ apartment_Sid: projectid }).then((res) => {
        this.yqjs.title= res.data.result[0].about_Name
        this.yqjs.text = res.data.result[0].about_Content;
        this.yqjs.img = staticurl + res.data.result[0].about_Logo;
      });
      yqlnapi({ apartment_Sid: projectid }).then((res) => {
        this.yqln.title=res.tag.notion_Main_Name    // 公司理念
        this.yqln.text=res.tag.notion_Main_Content  // 公司理念 富文本
        this.yqln.list = res.data.result.map((item, index) => {
          item.notion_Logo = staticurl + item.notion_Logo;
          return item;
        });
      });
      // 配套服务
      indexserviceapi({apartment_Sid : projectid}).then((res) => {
        this.service.list = res.data.result.map((item,index) => {
          item.service_Logo = staticurl + item.type_Image;
          return item;
        })
      })
      // 单页面服务
      singleapi({apartment_Sid : projectid}).then((res) => {
        //  this.single.list =
        // res.data.result.map((item,index) => {
        //     item.single_Logo = staticurl + item.single_Logo;
        //     // if(index==0)
        //     // { // 第一个单页 给
        //     //   cchx.title=item.single_Name
        //     //   cchx.logo=item.single_Logo
        //     //   cchx.content= item.single_Logo
        //     // }
        //   return item;
        // })
      })

      // 运营模式
      yymsapi({ apartment_Sid : projectid }).then((res) => {
        this.yyms.content = res.data.result[0];

        this.yyms.content.pattern_Logo=staticurl+res.data.result[0].pattern_Logo
      })

      //banner
      // swiperapi({ apartment_Sid: projectid, position_Sid: "2" }).then((res) => {
      //   this.swiper.imgs = res.data.result.map((item) => {
      //     item.imgurl = staticurl + item.picture;
      //     return item;
      //   });
      // });

    } else {
      this.swiper.imgs = [
              // { imgurl: "./assets/images/yqlnswiper1.jpg" }
            ]
      ;
      this.yqjs.text = `<div class="yqjstext1">
                       盼云跨境电子商务产业园，是一家以抖音小店店主、抖音主播为服务对象的综合电子商务产业园，为抖音百万商家及主播提供包括工商注册、财税服务、知识产权、供应链等一站式配套服务，免费提供注册地址，入园享受多项福利政策。
                    </div>
                    <div class="yqjstext1">
                      盼云跨境核心成员曾供职于央企、阿里巴巴，为多家主流电商平台提供过成功的行业解决方案，园区设税务规划、税务服务、风险管控的专业团队，成员均深耕财税行业5年以上，具备注册会计师、注册税务师资格。
                    </div>
                    <div class="yqjstext1">
                      盼云跨境以“登记注册在线化、市场主体多样化、线上线下一体化、园区管理智能化”为核心，借助庞大的商家主播群体，全方位打造一个集电商、直播、供应链于一体的新零售产业服务平台，以点带面，实现直播电商产业服务升级。盼云跨境旗下“抖小号”作为其移动端产品，集品宣、一键入园、服务选购、客服咨询四位一体，为广大入园商家提供更便捷、更全面的运营指南。
                    </div>`;

      this.yqjs.img = "./assets/images/us.jpg";
      this.yqln.list = [
        {
          notion_Logo: "./assets/images/yqln1.png",
          notion_Name: "成本低",
          notion_Content:
            "无需大量资金囤一键代发,无需美工客服,一人轻松打理店铺",
        },
        {
          notion_Logo: "./assets/images/yqln2.png",
          notion_Name: "门槛低",
          notion_Content:
            " 无需外语，不限基础，赠送店铺系统,一键铺货，一键上传，小白也能轻松上手",
        },
        {
          notion_Logo: "./assets/images/yqln3.png",
          notion_Name: "空间大",
          notion_Content: "平台产品单价高，客户消费能力强，产品利润可观",
        },
        {
          notion_Logo: "./assets/images/yqln4.png",
          notion_Name: "行业预期好",
          notion_Content: "跨境电商将在，未来很长一段时间内,保持高速增长",
        },
      ];
    }
  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      // initialSlide: 0,
      // direction: 'vertical',
      // effect : 'coverflow',
      mousewheelControl: true,
      allowSlidePrev: false,
      allowSlideNext: false,
      autoplay: {
        delay: 2000,
      },
      on: {
        init: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[0].classList;
          // console.log(slide)
          // slide.add('ani-slide');
        },
        transitionStart: function (swiper) {
          // for(let i = 0; i < swiper.slides.length; i++){
          //   	let slide = swiper.slides[i].classList;
          //     slide.remove('ani-slide');
          // }
        },
        transitionEnd: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[swiper.activeIndex].classList;
          // slide.add('ani-slide');
        },
      },
    });
    this.$nextTick(() => {
      new wow.WOW().init();
    });

    // 页面增加全局点击
    window.addEventListener("click", this.hideChat);
  },
  beforeDestroy() {
    /* 组件销毁的时候要移除侦听器 */
    window.removeEventListener("click", this.hideChat);
  },
};
</script>

<style scoped>

/*配套服务*/
  .ptfwwrap{
    width: 100%;
    background-image: url("../../assets/images/s.png");
    background-repeat: no-repeat;
    background-size:auto 60%;
    background-position: 99% center;
  }

  .ptfwcontent{
    width: 1050px;
    margin: 0 auto;
    margin-top: 200px;
  }

  .ptfwtitle{
    text-align: center;
    font-size: 30px;
    color: rgba(93, 46, 140, 1);
    line-height: 60px;
  }

  .ptfwtitle2{
    font-size: 15px;
    color: rgba(93, 46, 140, 1);
    text-align: center;
  }

  .fwxrow{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 78px;
  }

  .fwx_item{
    margin: 0 100px;
  }

  .fwxtext{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 140px;
    color: #333333;
  }

.servewrap {
  font-size: 12px;
}

.indexbox {
  width: 100%;
  overflow-x: hidden;
}

.swiper-container {
  width: 100%;
  /* height: 90%; */
  height: auto;
  position: relative;
  overflow: revert;
}

.swiperbgimg {
  width: 100%;
  height: 100%;
  /* background-image: url(""); */
  background-size: 100% 100%;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperimg {
  width: 100%;
  height: 100%;
}

.yqjswrap {
  width: 100%;
  /* background-image: url("../assets/images/A.png"); */
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position: 99% center;
}

.yqjscontent {
  width: 90%;
  margin: 0 auto;
  margin-top: 35px;
}

.yqjstitle {
  text-align: center;
  font-size: 30px;

  /*color: rgba(93, 46, 140, 1);*/
  color:white;
  line-height: 100px;
  background-image: url("../../assets/images/titleback.png");
  background-size: cover;
}

.yqjstitle2 {
  font-size: 15px;
  color: rgba(93, 46, 140, 1);
  text-align: center;
}

.yqjs_item {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.yqjsimgall {
  width: 100%;
}

.yqjsimg {
  width: 50%;
}

.yqjstext {
  line-height: 30px;
  box-sizing: border-box;
  padding: 30px 40px;
  font-size: 14px;
}
.yqjstextall {
  width: 100%;
  line-height: 30px;
  box-sizing: border-box;
  padding: 30px 40px;
  font-size: 14px;
}

.yqjstext1,
.yqjstext2 {
  letter-spacing: 1px;
  text-indent: 30px;
}
/*<!--公司理念-->*/
.yqlnwrap {
  width: 100%;
  /* background-image: url("../assets/images/A.png"); */
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position: 99% center;
  /*margin-bottom: 50px;*/
}

.yqlncontent {
  width: 90%;
  margin: 0 auto;
  margin-top: 215px;
}

.yqlntitle {
  text-align: center;
  font-size: 30px;
  color: rgba(93, 46, 140, 1);
  line-height: 60px;
}

.yqlntitle2 {
  font-size: 15px;
  color: rgba(93, 46, 140, 1);
  text-align: center;
}

.yqlntext {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1px;
  text-indent: 30px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.yqlnbox {
  display: flex;
  flex-wrap: wrap;
}

.yqln_item {
  display: flex;
  margin: 50px 0;
}

.yqln_item_img {
  width: 60px;
  height: 60px;
  align-self: center;
  margin: 0 47px;
}

.yqln_item_cont {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yqln_item_title {
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.yqln_item_text {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
}
@media (max-width: 600px){

}
</style>
